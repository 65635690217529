<template>
  <div style="margin-top: 30px">
    <div
      style="display: flex; justify-content: space-between; width: 90%; max-width: 1200px"
    >
      <div style="display: flex; gap: 8px">
        <CSelect v-model="sponsor_id" :options="sponsors" />
        <button class="saveBtn" @click="PublicidadesById">Seleccionar</button>
      </div>

      <button class="saveBtn" @click="() => (createModal = !createModal)">
        Crear nueva
      </button>
    </div>

    <!--     Tabla Publicidades-->
    <CDataTable
      :fields="fieldsPublicidades"
      :items="publicidades"
      :items-per-page="10"
      hover
      sorter
      pagination
      style="max-width: 850px"
    >
      <template #action="{ item }">
        <td class="py-2">
          <CButton
            class="mr-2"
            color="danger"
            variant="outline"
            square
            size="sm"
            @click="
              setCurrentItem(item, 'edit');
              currentAd = null;
            "
          >
            <img style="width: 1rem" src="../../assets/icons/svg/edit.svg" alt="" />
          </CButton>
          <CButton
            color="red"
            variant="outline"
            square
            size="sm"
            @click="setCurrentItem(item, 'delete')"
          >
            <img style="width: 1rem" src="../../assets/icons/svg/delete.svg" alt="" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <!--     Modal crear Publicidad-->
    <CModal
      style="width: 40% !important"
      class="modal-sm"
      title="Crear Publicidad"
      color="primary"
      :show.sync="createModal"
      size="sm"
      :closeOnBackdrop="false"
    >
      <CRow>
        <CCol sm="12" md="6">
          <CSelect label="sponsor" v-model="newAd.sponsor_id" :options="sponsors" />
        </CCol>
        <CCol sm="12" md="6">
          <CInput label="Nombre:" v-model="newAd.name" />
        </CCol>
        <CCol sm="12">
          <div style="max-width: 500px">
            <CSelect
              placeholder="Seleccione una opción"
              v-model="newAd.advertisement_type_id"
              label="Tipo de Anuncio:"
              :options="optionsAd_type"
            />
            <p>{{ descriptionSelectedAdType }}</p>
            <p style="margin-top: 0" v-if="newAd.advertisement_type_id == 8">
              *Una vez creado el anuncio se podrán añadir steps
            </p>
          </div>
        </CCol>
        <CCol sm="12" md="6">
          <CInput label="action_value:" v-model="newAd.action_value" />
        </CCol>
        <CCol sm="12" md="6">
          <div style="max-width: 500px">
            <CSelect
              placeholder="Seleccione una opción"
              v-model="newAd.action_type"
              label="Tipo de Acción:"
              :options="optionsAction_type"
            />
            <p>{{ descriptionSelectedActionType }}</p>
          </div>
        </CCol>
        <CCol sm="12">
          <label>Media:</label>
          <div
            style="max-width: 300px"
            class="input-group file-input-group"
            data-controller="file-input"
          >
            <input
              :value="fileNameNewAd"
              style="background-color: #fff"
              class="form-control"
              type="text"
              placeholder="Selecionar archivo"
              readonly
              data-target="file-input.value"
            />
            <input
              @change="onChangeMediaNewAd"
              type="file"
              class="form-control"
              id="customFile"
              data-target="file-input.input"
              data-action="file-input#display"
            />
            <div class="input-group-append">
              <label
                style="background-color: #fff"
                class="btn btn-secondary mb-0"
                for="customFile"
                ><img style="width: 20px" src="../../assets/icons/svg/clip.svg" alt=""
              /></label>
            </div>
          </div>
          <p style="color: red">{{ validNewAdMediaDimensions.message }}</p>
          <p style="color: red">{{ validateNewMediaAdType.message }}</p>
          <!--         <img v-if="previewMediaNewAd" class="img-preview" :src="previewMediaNewAd" alt="preview"> -->
        </CCol>
        <CCol sm="12" md="6">
          <label class="label-img">Fecha de Inicio: </label>
          <CInput
            placeholder="dd-mm-yyyy"
            format="dd-mm-yyyy"
            type="date"
            horizontal
            v-model="newAd.start_date"
          />
        </CCol>
        <CCol sm="12" md="6">
          <label class="label-img">Fecha de Término: </label>
          <CInput
            placeholder="dd-mm-yyyy"
            type="date"
            horizontal
            v-model="newAd.end_date"
          />
        </CCol>

        <CCol sm="12" md="6">
          <label class="label-img">Tiempo cierre forzado: </label>
          <CInput max="60" type="number" horizontal v-model="newAd.forced_time" />
          <span v-if="newAd.forced_time > 60" style="color: red"
            >El valor máximo no puede ser mayor a 60.</span
          >
        </CCol>
        <CCol sm="12" md="6">
          <label class="label-img">Tiempo máximo: </label>
          <CInput max="60" type="number" horizontal v-model="newAd.max_time" />
          <span v-if="newAd.max_time > 60" style="color: red"
            >El valor máximo no puede ser mayor a 60.</span
          >
        </CCol>
        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertSuccess" color="success"
            >Configuración actualizada</CAlert
          >
        </div>
        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertError" color="red"
            >Error al acutalizar</CAlert
          >
        </div>
      </CRow>
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="success"
          square
          :disabled="disabledCreateBtn"
          size="sm"
          @click="createPublicidad()"
        >
          <CSpinner v-if="loaderbtn" color="white" style="width: 1rem; height: 1rem" />
          <p v-else style="margin-bottom: 0">Guardar</p>
        </CButton>
        <CButton color="red" square size="sm" @click="() => (createModal = !createModal)">
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--     Modal Editar Publicidad / Editar o Crear Anuncio -->
    <CModal
      style="width: 40% !important"
      class="modal-sm"
      title="Editar Publicidad"
      color="primary"
      :show.sync="editModal"
      size="sm"
      :closeOnBackdrop="false"
    >
      <!--     Editar Publicidad -->
      <CRow style="padding-right: 20px">
        <CCol sm="12" md="6">
          <CSelect
            label="sponsor"
            v-model="currentPublicidad.sponsor_id"
            :options="sponsors"
          />
        </CCol>
        <CCol sm="12" md="6">
          <CInput label="Nombre:" v-model="currentPublicidad.name" />
        </CCol>
        <CCol sm="12" md="6">
          <label class="label-img">Fecha de Inicio: </label>
          <CInput type="date" horizontal v-model="currentPublicidad.start_date" />
        </CCol>
        <CCol sm="12" md="6">
          <label class="label-img">Fecha de Término: </label>
          <CInput type="date" horizontal v-model="currentPublicidad.end_date" />
        </CCol>
        <CButton
          class="ml-3 mb-2"
          color="success"
          square
          :disabled="disabledUpdatePublicidadBtn"
          size="sm"
          @click="updatePublicidad()"
        >
          <CSpinner v-if="loaderbtn" color="white" style="width: 1rem; height: 1rem" />
          <p v-else style="margin-bottom: 0">Guardar</p>
        </CButton>

        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertSuccessAd" color="success"
            >Configuración actualizada</CAlert
          >
        </div>
        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertErrorAd" color="red"
            >Error al acutalizar</CAlert
          >
        </div>
        <CCol sm="12">
          <div style="max-width: 850px">
            <CButton
              style="margin-left: auto; display: flex"
              class="mr-2"
              color="info"
              variant="outline"
              square
              size="sm"
              @click="SetaddAd()"
            >
              <div style="display: flex; align-items: center; gap: 6px">
                <font-awesome-icon icon="plus" style="font-size: 20px" />
                <span>Añadir Nuevo Anuncio</span>
              </div>
            </CButton>
          </div>

          <!--           Tabla Anuncios -->
          <h4>Anuncios</h4>
          <CDataTable
            :loading="!loaderAdds"
            :fields="fieldsAds"
            :items="ads"
            :items-per-page="10"
            hover
            sorter
            pagination
            style="max-width: 850px"
          >
            <template #name="{ item }">
              <td class="py-2">
                {{ item.advertisement_type.name }}
              </td>
            </template>
            <template #action="{ item }">
              <td class="py-2">
                <CButton
                  class="mr-2"
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                  @click="setCurrentAd(item.id, 'edit')"
                >
                  <img style="width: 1rem" src="../../assets/icons/svg/edit.svg" alt="" />
                </CButton>
                <CButton
                  class="mr-2"
                  color="red"
                  variant="outline"
                  square
                  size="sm"
                  @click="setCurrentAd(item.id, 'delete')"
                >
                  <img
                    style="width: 1rem"
                    src="../../assets/icons/svg/delete.svg"
                    alt=""
                  />
                </CButton>
                <CButton
                  class="mr-2"
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                  @click="openActionsModal(item.id)"
                >
                  <img
                    style="width: 1rem"
                    src="../../assets/icons/svg/eye-solid.svg"
                    alt=""
                  />
                </CButton>
                <CButton
                  class="mr-2"
                  color="danger"
                  variant="outline"
                  square
                  size="sm"
                  @click="
                    modalChooseNotificationUsers = true;
                    advertisement_id = item.id;
                  "
                >
                  <img
                    style="width: 1rem"
                    src="../../assets//imgicons/ICONOS SOSRecurso 27.png"
                    alt=""
                  />
                </CButton>
                <!--                 <CDropdown
                style="display: inline-block;"
                  v-if="item.type_id == 8 || item.type_id == 1 "
                  variant="outline"
                  square
                  size="sm"
                >
                <template slot="toggler-content">
                  <img style="width: 1rem;" src="../../assets//imgicons/ICONOS SOSRecurso 27.png" alt=""/>
                </template>
                <CDropdownItem @click="sendNotification(item.id)">Enviar notificación a todos</CDropdownItem>
                <CDropdownItem @click="modalChooseNotificationUsers = true; advertisement_id = item.id;">Elegir usuarios</CDropdownItem>
                </CDropdown> -->
              </td>
            </template>
          </CDataTable>
          <div
            class="alert"
            style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
          >
            <CAlert
              style="margin-bottom: 0; width: 300px"
              v-if="alertSuccessNotification"
              color="success"
              >Notificación Enviada</CAlert
            >
          </div>
          <div
            class="alert"
            style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
          >
            <CAlert
              style="margin-bottom: 0; width: 300px"
              v-if="alertErrorNotification"
              color="red"
              >Error</CAlert
            >
          </div>
        </CCol>

        <!--           Editar / Crear Anuncio -->
        <CRow style="padding-left: 20px" v-if="currentAd">
          <CCol sm="12">
            <h4>
              {{ updateOrAdd == "update" ? "Editar Anuncio" : "Nuevo Anuncio" }}
            </h4>
            <div style="max-width: 670px; display: flex; align-items: center; gap: 40px">
              <div>
                <CSelect
                  placeholder="Seleccione una opción"
                  v-model="currentAd.type_id"
                  label="Tipo de Anuncio:"
                  :options="optionsAd_type"
                />
                <p>{{ descriptionSelectedAdTypeUpdate }}</p>
              </div>

              <div style="display: flex; flex-direction: column">
                <CButton
                  v-show="updateOrAdd == 'update' && currentAd.type_id == 8"
                  :disabled="savedTypeStepper"
                  color="info"
                  variant="outline"
                  square
                  size="sm"
                  style="
                    height: 30px;
                    width: 200px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                  "
                  @click="openStepModal"
                >
                  <font-awesome-icon icon="plus" style="font-size: 20px" />
                  Añadir Paso
                </CButton>
                <span
                  v-show="updateOrAdd == 'update' && currentAd?.type_id == 8"
                  v-if="savedTypeStepper"
                  >*Guarde la configuración para añadir un paso.</span
                >
              </div>
            </div>
          </CCol>

          <CCol sm="12" md="6" v-if="currentAd.type_id !== 8">
            <div style="max-width: 500px">
              <CSelect
                placeholder="Seleccione una opción"
                v-model="currentAd.action_type"
                label="Tipo de Acción:"
                :options="optionsAction_type"
              />
              <p>{{ descriptionSelectedActionTypeUpdate }}</p>
            </div>
          </CCol>
          <CCol sm="12" md="6" v-if="currentAd.type_id !== 8">
            <CInput label="action_value:" v-model="currentAd.action_value" />
          </CCol>

          <CCol sm="12" md="6">
            <label>Media:</label>
            <div
              style="max-width: 300px"
              class="input-group file-input-group"
              data-controller="file-input1"
            >
              <input
                :value="fileNameUpdateAd"
                style="background-color: #fff"
                class="form-control"
                type="text"
                :placeholder="
                  updateOrAdd == 'update' ? 'Actualizar imagen' : 'Seleccionar un archivo'
                "
                readonly
                data-target="file-input1.value"
              />
              <input
                @change="onChangeMediaUpdateAd"
                type="file"
                class="form-control"
                id="customFile1"
                data-target="file-input1.input"
                data-action="file-input1#display"
              />
              <div class="input-group-append">
                <label
                  style="background-color: #fff"
                  class="btn btn-secondary mb-0"
                  for="customFile1"
                  ><img style="width: 20px" src="../../assets/icons/svg/clip.svg" alt=""
                /></label>
              </div>
            </div>
            <p style="color: red">{{ validUpdateAdMediaDimensions.message }}</p>
            <p style="color: red">{{ validateUpdateMediaAdType.message }}</p>
          </CCol>
          <CCol sm="12" md="6">
            <img
              v-if="previewUpdateMediaAd"
              class="img-preview"
              :src="previewUpdateMediaAd"
              alt="preview"
            />
          </CCol>

          <CCol sm="12" md="6">
            <label class="label-img">Tiempo cierre forzado: </label>
            <CInput max="60" type="number" horizontal v-model="currentAd.forced_time" />
            <span v-if="currentAd.forced_time > 60" style="color: red"
              >El valor máximo no puede ser mayor a 60.</span
            >
          </CCol>
          <CCol sm="12" md="6">
            <label class="label-img">Tiempo máximo: </label>
            <CInput type="number" horizontal v-model="currentAd.max_time" />
            <span v-if="currentAd.max_time > 60" style="color: red"
              >El valor máximo no puede ser mayor a 60.</span
            >
          </CCol>

          <!--           Tabla Steps -->
          <CCol
            v-show="updateOrAdd == 'update' && currentAd?.type_id == 8"
            style="margin-top: 20px"
          >
            <h4>Steps</h4>
            <CDataTable
              :loading="!loaderAdds"
              :fields="fieldsSteps"
              :items="currentAd.step_config"
              :items-per-page="10"
              hover
              sorter
              pagination
              style="max-width: 850px"
            >
              <template #name="{ item }">
                <td class="py-2">
                  {{ item.title }}
                </td>
              </template>
              <template #action="{ item }">
                <td class="py-2">
                  <CButton
                    class="mr-2"
                    color="danger"
                    variant="outline"
                    square
                    size="sm"
                    @click="setStep(item, 'edit')"
                  >
                    <img
                      style="width: 1rem"
                      src="../../assets/icons/svg/edit.svg"
                      alt=""
                    />
                  </CButton>
                  <CButton
                    color="red"
                    variant="outline"
                    square
                    size="sm"
                    @click="setStep(item, 'delete')"
                  >
                    <img
                      style="width: 1rem"
                      src="../../assets/icons/svg/delete.svg"
                      alt=""
                    />
                  </CButton>
                </td>
              </template>
            </CDataTable>
          </CCol>
        </CRow>
        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertSuccessAd" color="success">{{
            updateOrAdd == "update" ? "Configuración actualizada" : "Anuncio Creado"
          }}</CAlert>
        </div>
        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertErrorAd" color="red"
            >Error al acutalizar</CAlert
          >
        </div>
      </CRow>

      <div slot="footer" class="w-100">
        <CButton
          v-show="updateOrAdd"
          class="mr-2"
          color="success"
          square
          :disabled="disabledUpdateAdBtn"
          size="sm"
          @click="updateOrAdd == 'update' ? updateAd() : addAd()"
        >
          <CSpinner v-if="loaderbtn" color="white" style="width: 1rem; height: 1rem" />
          <p v-else style="margin-bottom: 0">Guardar</p>
        </CButton>
        <CButton
          color="red"
          square
          size="sm"
          @click="
            editModal = !editModal;
            currentAd = null;
          "
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--     Modal Eliminar Publicidad / Anuncio-->
    <CModal
      class="modal-delete"
      style="max-width: 500px !important"
      centered
      :title="publicidadOrAd == 'ad' ? 'Eliminar Anuncio' : 'Eliminar Publicidad'"
      color="primary"
      :show.sync="deleteModal"
      size="sm"
    >
      ¿Seguro que quieres eliminar
      {{ publicidadOrAd == "ad" ? "el anuncio" : "la publicidad" }}
      <span style="font-weight: 800">{{
        publicidadOrAd == "ad"
          ? currentAd?.advertisement_type?.name
          : currentPublicidad?.name
      }}</span
      >?
      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="red"
          variant="outline"
          square
          @click="publicidadOrAd == 'ad' ? deleteAnuncio() : deletePublicidad()"
        >
          Eliminar
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="() => (deleteModal = !deleteModal)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--        Modal Añadir/Editar Step -->
    <CModal
      centered
      :title="createOrUpdateStep == 'update' ? 'Editar Step' : 'Añadir Step'"
      color="primary"
      :show.sync="createStepModal"
      size="sm"
    >
      <CRow>
        <CCol sm="12" md="6">
          <CInput label="Título:" v-model="currentStep.title" />
        </CCol>
        <CCol sm="12" md="6">
          <CInput label="Descripción:" v-model="currentStep.description" />
        </CCol>
        <CCol sm="12" md="6">
          <CSelect
            placeholder="Seleccione una opción"
            v-model="currentStep.type"
            label="Tipo:"
            :options="optionstepper_type"
          />
        </CCol>
        <CCol sm="12" md="6">
          <CInput
            label="Orden:"
            max="60"
            type="number"
            vertical
            v-model="currentStep.order"
          />
        </CCol>
        <CCol sm="12">
          <CRow>
            <CCol sm="6">
              <label>Media:</label>
              <div
                style="max-width: 300px"
                class="input-group file-input-group"
                data-controller="file-input3"
              >
                <input
                  :value="fileNamecurrentStep"
                  style="background-color: #fff"
                  class="form-control"
                  type="text"
                  :placeholder="
                    createOrUpdateStep == 'update'
                      ? 'Actualizar imagen'
                      : 'Seleccionar un archivo'
                  "
                  readonly
                  data-target="file-input3.value"
                />
                <input
                  @change="onChangeMediacurrentStep"
                  type="file"
                  class="form-control"
                  id="customFile3"
                  data-target="file-input3.input"
                  data-action="file-input3#display"
                />
                <div class="input-group-append">
                  <label
                    style="background-color: #fff"
                    class="btn btn-secondary mb-0"
                    for="customFile3"
                    ><img
                      style="width: 20px"
                      src="../../assets/icons/svg/clip.svg"
                      alt=""
                  /></label>
                </div>
              </div>
              <p style="color: red">{{ validateStepMediaType.message }}</p>
            </CCol>
            <CCol sm="6">
              <img
                v-if="previewcreatecurrentStep"
                :src="previewcreatecurrentStep"
                alt="preview"
                class="img-preview"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" md="6">
          <div style="max-width: 500px">
            <CSelect
              placeholder="Seleccione una opción"
              v-model="currentStep.action_type"
              label="Tipo de Acción:"
              :options="optionsAction_type"
            />
            <p>{{ descriptionSelectedActionType }}</p>
          </div>
        </CCol>
        <CCol sm="12" md="6">
          <CInput
            v-if="currentStep.action_type == 'to_url'"
            label="action_value:"
            v-model="stepsActionToURL"
          />
          <div v-if="currentStep.action_type == 'to_app_route'">
            <CInput label="Nombre ruta:" v-model="stepsActionToAppRoute.name"></CInput>
            <CButton
              style="height: 40px; margin-left: 20px"
              v-if="!addStepBtn"
              @click="addStepBtn = true"
              color="primary"
            >
              <font-awesome-icon icon="plus" style="font-size: 20px" />
              Añadir Parámetros
            </CButton>
            <div v-if="hasParams" style="display: flex; align-items: center">
              <div>
                <div
                  v-for="(param, i) in stepsActionToAppRoute.params"
                  :key="i"
                  style="display: flex; align-items: center"
                >
                  <hr v-if="i !== 0" class="divider" />
                  <div style="display: flex; gap: 20px">
                    <label
                      >Param {{ i + 1 }}:<CInput
                        style="padding-top: 10px"
                        v-model="stepsActionToAppRoute.params[i].key"
                    /></label>
                    <CInput
                      label="Valor:"
                      v-model="stepsActionToAppRoute.params[i].value"
                    />
                  </div>
                  <CButton
                    style="height: 40px; margin-left: 20px"
                    v-if="i == 0"
                    @click="onAddParam"
                    color="primary"
                  >
                    <font-awesome-icon icon="plus" style="font-size: 20px" />
                  </CButton>
                  <CButton
                    @click="deleteParam(i)"
                    v-if="i !== 0"
                    color="red"
                    style="display: flex; align-items: center; margin-left: 20px"
                    ><font-awesome-icon icon="minus" style="font-size: 20px"
                  /></CButton>
                </div>
              </div>
            </div>
          </div>
        </CCol>
        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertSuccessStep" color="success">{{
            createOrUpdateStep == "update" ? "Paso Actualizado" : "Paso Creado"
          }}</CAlert>
        </div>
        <div
          class="alert"
          style="margin-bottom: 0; padding: 0; margin-left: 18px; margin-right: 8px"
        >
          <CAlert style="margin-bottom: 0" v-if="alertErrorStep" color="red"
            >Error al acutalizar</CAlert
          >
        </div>
      </CRow>

      <div slot="footer" class="w-100">
        <CButton
          class="mr-2"
          color="success"
          square
          :disabled="disabledStepBtn"
          size="sm"
          @click="createOrUpdateStep == 'update' ? updateStep() : createStep()"
        >
          <CSpinner v-if="loaderbtn" color="white" style="width: 1rem; height: 1rem" />
          <p v-else style="margin-bottom: 0">Guardar</p>
        </CButton>
        <CButton
          color="red"
          square
          size="sm"
          @click="
            createStepModal = !createStepModal;
            currentStep == currentStepModel;
          "
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--           Modal Eliminar Step -->
    <CModal
      class="modal-delete"
      style="max-width: 500px !important"
      centered
      title="Eliminar Step"
      color="primary"
      :show.sync="deleteStepModal"
      size="sm"
    >
      ¿Seguro que quieres eliminar el step
      <span style="font-weight: 800">{{ currentStep?.title }}</span
      >?
      <div slot="footer" class="w-100">
        <CButton class="mr-2" color="red" variant="outline" square @click="deleteStep()">
          Eliminar
        </CButton>
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="() => (deleteModal = !deleteModal)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>

    <!--            Modal Acciones -->
    <CModal
      style="max-width: 500px !important"
      centered
      title="Acciones"
      color="primary"
      :show.sync="actionsModal"
      size="sm"
    >
      <CCardBody>
        <CDataTable
          hover
          striped
          :items="actions"
          :fields="fieldsActions"
          :items-per-page="pagination.perPage"
          index-column
          clickable-rows
        >
          <template #name="{ item }">
            <td>{{ item.user.name }} {{ item.user.lastname }}</td>
          </template>
          <template #date="{ item }">
            <td>{{ item.created_at | filterDate() }}</td>
          </template>
        </CDataTable>
        <Pagination :data="pagination" :callback="paginate" />
      </CCardBody>
    </CModal>

    <!--            Modal Elegir Notificación -->
    <CModal
      style="max-width: 500px !important"
      centered
      title="Seleccionar Usuarios"
      color="primary"
      :show.sync="modalChooseNotificationUsers"
      size="sm"
    >
      <SearchUsers :advertisement_id="advertisement_id" :close="closeNotificationModal" :modalChooseNotificationUsers="modalChooseNotificationUsers" :title="currentPublicidad.name"/>
      <div slot="footer" class="w-100">
        <CButton
          color="secondary"
          variant="outline"
          square
          @click="() => (modalChooseNotificationUsers = !modalChooseNotificationUsers)"
        >
          Cancelar
        </CButton>
      </div>
    </CModal>
  </div>
</template>

<script>
import Pagination from "../../helpers/Pagination";
import {
  createAdvertisement,
  getAdvertisementTypes,
  getCompanyAdvertisement,
  getAdvertisementDetail,
  createAdvertisementConfig,
  updateAdvertisement,
  updateAdvertisementConfig,
  deleteAdvertisement,
  deleteAdvertisementConfig,
  createAdvertisementStepConfig,
  deleteAdvertisementStepConfig,
  updateAdvertisementStepConfig,
  sendAdvertisementNotification,
  getAdvertisementActions,
} from "../../api/publicidad";
import { getAdminSponsors } from "../../api/sponsor";
import SearchUsers from "../widgets/SearchUsers.vue";
import moment from "moment";
export default {
  components: { Pagination, SearchUsers },
  data() {
    return {
      sponsor_id: null,
      advertisement_id: null,
      loaderbtn: false,
      loaderAdds: false,
      updateOrAdd: null,
      publicidades: [],
      ads: [],
      currentPublicidad: {
        name: "",
        start_date: "",
        end_date: "",
        id: "",
      },
      currentAd: null,
      originalCurrentAd: null,
      fieldsPublicidades: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      fieldsAds: [
        {
          key: "name",
          label: "Tipo de anuncio",
          _props: { scope: "col" },
        },
        {
          key: "views_count",
          label: "Visualizaciones",
          _props: { scope: "col" },
        },
        {
          key: "actions_count",
          label: "Acciones",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      fieldsSteps: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "action",
          label: "Acción",
          _props: { scope: "col" },
        },
      ],
      fieldsActions: [
        {
          key: "name",
          label: "Nombre",
          _props: { scope: "col" },
        },
        {
          key: "date",
          label: "Fecha",
          _props: { scope: "col" },
        },
      ],
      actions: [],
      addParamsBtn: true,

      //alerts
      alertSuccess: false,
      alertError: false,
      alertSuccessAd: false,
      alertErrorAd: false,
      alertSuccessStep: false,
      alertErrorStep: false,

      //modals
      createModal: false,
      editModal: false,
      deleteModal: false,
      createStepModal: false,
      deleteStepModal: false,
      actionsModal: false,
      modalChooseNotificationUsers: false,

      publicidadOrAd: null,
      createOrUpdateStep: "",

      addStepBtn: false,

      optionsAd_type: [],
      optionsAction_type: [
        {
          value: "to_url",
          label: "Hacia URL",
          description: "Se dirigirá al usuario a la web indicada",
        },
        {
          value: "to_app_route",
          label: "Hacia ruta dentro de la App",
          description: "Se dirigirá al usuario a la ruta indicada, dentro de la app",
        },
        {
          value: "next_step",
          label: "Hacia el paso siguiente",
          description: "Paso siguiente (sólo para los paso a paso)",
        },
      ],
      optionstepper_type: ["single_media"],
      newAd: {
        sponsor_id: null,
        name: "",
        advertisement_type_id: "",
        action_value: "",
        action_type: "",
        media: "",
        start_date: "",
        end_date: "",
        forced_time: "",
        max_time: "",
      },

      dimensions: {
        1: true,
        2: { width: 320, height: 100 },
        3: { width: 320, height: 50 },
        4: { width: 320, height: 100 },
        5: { width: 320, height: 250 },
        6: { width: 320, height: 100 },
        7: "",
        8: "",
      },
      currentStepModel: {
        title: "",
        description: "",
        type: "",
        action_type: "",
        action_value: "",
      },
      stepsActionToAppRouteModel: {
        name: "",
        params: [
          {
            key: "",
            value: "",
          },
        ],
      },
      currentStep: {
        title: "",
        description: "",
        type: "",
        action_type: "",
        action_value: "",
        media: "",
      },
      /*       currentStep: {}, */
      originalMediaStep: "",
      stepsActionToAppRoute: {
        name: "",
        params: [
          {
            key: "",
            value: "",
          },
        ],
      },
      stepsActionToURL: "",

      //files
      fileNameNewAd: "",
      previewMediaNewAd: "",
      mediaNewAd: {
        height: "",
        width: "",
        ratio: true,
      },
      fileTypeNewAd: null,

      fileNameUpdateAd: "",
      previewUpdateMediaAd: "",
      mediaCurrentAd: {
        height: "",
        width: "",
        ratio: true,
      },
      fileTypeUpdateAd: null,

      fileNamecurrentStep: "",
      previewcreatecurrentStep: "",
      fileTypeStep: null,

      alertSuccessNotification: false,
      alertErrorNotification: false,

      perPage: 20,
      page: 0,
      pagination: {
        page: 1,
        perPage: 10,
        total: null,
      },
      idAddForActions: null,
      sponsors: [],
    };
  },
  computed: {
    savedTypeStepper() {
      if (
        this.currentAd.type_id == 8 &&
        this.currentAd.type_id != this.originalCurrentAd?.type_id
      ) {
        return true;
      } else {
        return false;
      }
    },
    hasParams() {
      if (this.createOrUpdateStep == "create" && !this.addStepBtn) {
        return false;
      } else if (this.createOrUpdateStep == "create" && this.addStepBtn) {
        return true;
      } else if (
        this.createOrUpdateStep == "update" &&
        this.stepsActionToAppRoute?.params?.length
      ) {
        return true;
      }
    },
    descriptionSelectedAdType() {
      return (
        this.optionsAd_type.filter(
          (type) => type.id == this.newAd.advertisement_type_id
        )[0]?.description || ""
      );
    },
    descriptionSelectedAdTypeUpdate() {
      return (
        this.optionsAd_type.filter((type) => type.id == this.currentAd.type_id)[0]
          ?.description || ""
      );
    },
    descriptionSelectedActionType() {
      return (
        this.optionsAction_type.filter((type) => type.value == this.newAd.action_type)[0]
          ?.description || ""
      );
    },
    descriptionSelectedActionTypeUpdate() {
      return (
        this.optionsAction_type.filter(
          (type) => type.value == this.currentAd.action_type
        )[0]?.description || ""
      );
    },
    validNewAdMediaDimensions() {
      let rule = this.dimensions[this.newAd.advertisement_type_id];
      let message = "";
      let valid = false;

      if (
        this.newAd.advertisement_type_id == 7 ||
        this.newAd.advertisement_type_id == 8
      ) {
        message = "";
        valid = true;
      } else if (!this.newAd.media || !this.newAd.advertisement_type_id) {
        message = "";
        valid = false;
      } else if (this.newAd.advertisement_type_id == 1) {
        message = !this.mediaNewAd.ratio
          ? "La relación de aspecto de la imagen debe ser de 9:16"
          : "";
        valid = !this.mediaNewAd.ratio ? false : true;
      } else {
        message =
          rule?.height !== this.mediaNewAd.height || rule?.width !== this.mediaNewAd.width
            ? `Las dimensiones de la imagen deben ser de ${rule?.width}x${rule?.height}`
            : "";
        valid =
          rule?.height !== this.mediaNewAd.height || rule?.width !== this.mediaNewAd.width
            ? false
            : true;
      }
      return { valid, message };
    },
    validUpdateAdMediaDimensions() {
      let rule = this.dimensions[this.currentAd.type_id];
      let message = "";
      let valid = false;

      if (this.currentAd.type_id == 7 || this.currentAd.type_id == 8) {
        message = "";
        valid = true;
      } else if (!this.currentAd.media || !this.currentAd.type_id) {
        message = "";
        valid = false;
      } else if (this.currentAd.type_id == 1) {
        message = !this.mediaCurrentAd.ratio
          ? "La relación de aspecto de la imagen debe ser de 9:16"
          : "";
        valid = !this.mediaCurrentAd.ratio ? false : true;
      } else {
        message =
          rule?.height !== this.mediaCurrentAd.height ||
          rule?.width !== this.mediaCurrentAd.width
            ? `Las dimensiones de la imagen deben ser de ${rule?.width}x${rule?.height}`
            : "";
        valid =
          rule?.height !== this.mediaCurrentAd.height ||
          rule?.width !== this.mediaCurrentAd.width
            ? false
            : true;
      }
      return { valid, message };
    },
    validateNewMediaAdType() {
      const validTypes = ["image/png", "image/jpeg", "image/jpg", "video/mp4"];

      if (!this.fileTypeNewAd) {
        return { message: "", valid: true };
      }
      if (this.fileTypeNewAd && validTypes.includes(this.fileTypeNewAd)) {
        return { message: "", valid: true };
      } else {
        return { message: "Formatos permitidos: png, jpeg, jpg, mp4", valid: false };
      }
    },
    validateUpdateMediaAdType() {
      const validTypes = ["image/png", "image/jpeg", "image/jpg", "video/mp4"];

      if (!this.fileTypeUpdateAd) {
        return { message: "", valid: true };
      }
      if (this.fileTypeUpdateAd && validTypes.includes(this.fileTypeUpdateAd)) {
        return { message: "", valid: true };
      } else {
        return { message: "Formatos permitidos: png, jpeg, jpg, mp4", valid: false };
      }
    },
    validateStepMediaType() {
      const validTypes = ["image/png", "image/jpeg", "image/jpg", "video/mp4"];

      if (!this.fileTypeStep) {
        return { message: "", valid: true };
      }
      if (this.fileTypeStep && validTypes.includes(this.fileTypeStep)) {
        return { message: "", valid: true };
      } else {
        return { message: "Formatos permitidos: png, jpeg, jpg, mp4", valid: false };
      }
    },

    disabledCreateBtn() {
      if (!this.validateNewMediaAdType.valid) {
        return true;
      } else if (this.newAd.forced_time > 60 || this.newAd.max_time > 60) {
        return true;
      }
      if (
        this.newAd.advertisement_type_id == 8 &&
        (!this.newAd.name ||
          !this.newAd.media ||
          !this.newAd.start_date ||
          !this.newAd.end_date ||
          !this.newAd.end_date ||
          !this.newAd.forced_time ||
          !this.newAd.max_time ||
          !this.validNewAdMediaDimensions.valid)
      ) {
        return true;
      } else if (
        this.newAd.advertisement_type_id !== 8 &&
        (!this.newAd.name ||
          !this.newAd.action_value ||
          !this.newAd.action_type ||
          !this.newAd.media ||
          !this.newAd.start_date ||
          !this.newAd.end_date ||
          !this.newAd.forced_time ||
          !this.newAd.max_time ||
          !this.validNewAdMediaDimensions.valid)
      ) {
        return true;
      } else if (!this.newAd.advertisement_type_id) {
        return true;
      } else false;
    },
    disabledUpdateAdBtn() {
      if (this.currentAd?.forced_time > 60 || this.currentAd?.max_time > 60) {
        return true;
      }
      if (
        this.currentAd?.type_id == 8 &&
        (!this.currentAd?.media ||
          !this.currentAd?.forced_time ||
          !this.currentAd?.max_time ||
          !this.validUpdateAdMediaDimensions.valid)
      ) {
        return true;
      } else if (
        this.currentAd?.type_id !== 8 &&
        (!this.currentAd?.action_value ||
          !this.currentAd?.action_type ||
          !this.currentAd?.media ||
          !this.currentAd?.forced_time ||
          !this.currentAd?.max_time ||
          !this.validUpdateAdMediaDimensions.valid)
      ) {
        return true;
      } else if (!this.currentAd?.type_id) {
        return true;
      } else false;
    },
    disabledUpdatePublicidadBtn() {
      if (
        !this.currentPublicidad?.name ||
        !this.currentPublicidad?.start_date ||
        !this.currentPublicidad?.end_date
      ) {
        return true;
      } else false;
    },
    disabledStepBtn() {
      if (
        !this.currentStep.title ||
        !this.currentStep.description ||
        !this.currentStep.action_type ||
        !this.currentStep.type ||
        !this.currentStep.order ||
        !this.currentStep.media
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    paginate(page, perPage) {
      this.page = page;
      this.pagination = {
        page,
        perPage: perPage,
        total: this.pagination.total,
      };
      this.openActionsModal(this.idAddForActions);
    },
    getTypes() {
      getAdvertisementTypes().then((res) => {
        const arr = res.advertisement_types.map((type) => {
          const obj = { ...type };
          obj.value = type.id;
          obj.label = type.name;
          return obj;
        });
        this.optionsAd_type = arr;
      });
    },
    getPublicidades(sponsor_id) {
      const data = {
        sponsor_id: this.sponsor_id || this.$store.state.userData.sponsor.id,
      };
      getCompanyAdvertisement(data).then((res) => {
        this.publicidades = res.advertisement;
      });
    },
    setCurrentItem(item, modal) {
      this.publicidadOrAd = "publicidad";
      if (modal == "delete") {
        this.currentPublicidad = { ...item };
        this.deleteModal = !this.deleteModal;
      } else if (modal == "edit") {
        this.ads = [];
        this.loaderAdds = false;
        const data = { advertisement_id: item.id };
        getAdvertisementDetail(data).then((res) => {
          this.ads = res.advertisement;
          this.loaderAdds = true;
        });
        this.currentPublicidad.name = item.name;
        this.currentPublicidad.start_date = moment(item.start_date).format("YYYY-MM-DD");
        this.currentPublicidad.end_date = moment(item.end_date).format("YYYY-MM-DD");
        this.currentPublicidad.id = item.id;
        this.currentPublicidad.sponsor_id = item.entity_id;
        this.editModal = !this.editModal;
      }
    },
    setCurrentAd(id, editOrDelete) {
      this.publicidadOrAd = "ad";
      this.currentAd = this.ads.filter((ad) => ad.id == id)[0];
      this.addStepBtn = this.currentAd?.step_config.length ? true : false;

      if (editOrDelete == "delete") {
        this.deleteModal = !this.deleteModal;
      } else if (editOrDelete == "edit") this.originalCurrentAd = { ...this.currentAd };
      this.updateOrAdd = "update";
      this.previewUpdateMediaAd = this.currentAd.media?.media_link || "";
    },
    //nuevo anuncio vacío
    SetaddAd() {
      this.updateOrAdd = "add";
      this.previewUpdateMediaAd = "";
      this.fileNameUpdateAd = "";
      this.currentAd = {
        type_id: "",
        action_value: "",
        action_type: "",
        max_time: "",
        forced_time: "",
        media: "",
      };
    },
    //agregar nuevo anuncio
    addAd() {
      const data = new FormData();
      const obj = { ...this.currentAd };
      obj.advertisement_id = this.currentPublicidad.id;
      obj.advertisement_type_id = obj.type_id;
      obj.sponsor_id = this.currentPublicidad.sponsor_id
      delete obj.type_id;

      if (obj.advertisement_type_id == 8) {
        delete obj.action_value;
        delete obj.action_type;
      }

      for (const property in obj) {
        data.append(property, obj[property]);
      }
      this.loaderbtn = true;
      createAdvertisementConfig(data)
        .then((res) => {
          this.loaderbtn = false;
          this.alertSuccessAd = true;
          setTimeout(() => {
            this.alertSuccessAd = false;
            const data = { advertisement_id: this.currentPublicidad.id };
            getAdvertisementDetail(data).then((res) => {
              this.ads = res.advertisement;
              this.loaderAdds = true;
            });
          }, 1000);
        })
        .catch((e) => {
          this.loaderbtn = false;
          this.alertErrorAd = true;
          setTimeout(() => {
            this.alertErrorAd = false;
          }, 1000);
        });
    },
    updatePublicidad() {
      const data = new FormData();
      const obj = { ...this.currentPublicidad };
      obj.start_date = moment(obj.start_date).format("YYYY-MM-DD h:mm:ss");
      obj.end_date = moment(obj.end_date).format("YYYY-MM-DD h:mm:ss");
      obj.advertisement_id = this.currentPublicidad.id;
      delete obj.id;

      for (const property in obj) {
        data.append(property, obj[property]);
      }
      data.append("_method", "PATCH");
      this.loaderbtn = true;
      updateAdvertisement(data)
        .then((res) => {
          this.alertSuccessAd = true;
          setTimeout(() => {
            this.loaderbtn = false;
            this.alertSuccessAd = false;
            this.getPublicidades();
            this.editModal = false;
          }, 1000);
        })
        .catch((e) => {
          this.loaderbtn = false;
          this.alertErrorAd = true;
          setTimeout(() => {
            this.alertErrorAd = false;
          }, 1000);
        });
    },
    updateAd() {
      const data = new FormData();
      const obj = { ...this.currentAd };
      for (const property in obj) {
        if (obj[property] !== this.originalCurrentAd[property]) {
          data.append(property, obj[property]);
        }
      }
      data.append("_method", "PATCH");
      data.append("advertisement_config_id", obj.id);
      this.loaderbtn = true;
      updateAdvertisementConfig(data)
        .then((res) => {
          this.alertSuccessAd = true;
          setTimeout(() => {
            this.loaderbtn = false;
            const data = { advertisement_id: this.currentPublicidad.id };
            getAdvertisementDetail(data).then((res) => {
              this.originalCurrentAd = res.advertisement.filter(
                (ad) => ad.id == this.currentAd.id
              )[0];
              this.loaderAdds = true;
              this.alertSuccessAd = false;
            });
          }, 1000);
        })
        .catch((e) => {
          this.loaderbtn = false;
          this.alertErrorAd = true;
          setTimeout(() => {
            this.alertErrorAd = false;
          }, 1000);
        });
    },
    deletePublicidad() {
      const data = { advertisement_id: this.currentPublicidad.id };
      deleteAdvertisement(data).then((res) => {
        this.deleteModal = !this.deleteModal;
        this.getPublicidades().then((res) => {
          this.getPublicidades();
          this.deleteModal = false;
        });
      });
    },
    deleteAnuncio() {
      const data = { advertisement_config_id: this.currentAd.id };
      deleteAdvertisementConfig(data).then((res) => {
        this.deleteModal = !this.deleteModal;

        const data = { advertisement_id: this.currentPublicidad.id };
        getAdvertisementDetail(data).then((res) => {
          this.ads = res.advertisement;
          this.loaderAdds = true;
        });
      });
    },
    createStep() {
      const data = new FormData();
      const obj = this.currentStep;
      if (this.currentStep.action_type == "to_app_route") {
        const paramsformat = this.stepsActionToAppRoute.params.map((param) => {
          return { [param.key]: param.value };
        });
        obj.action_value = { ...this.stepsActionToAppRoute };
        obj.action_value.params = paramsformat;
        obj.action_value = JSON.stringify({ ...obj.action_value });
      } else if (this.currentStep.action_type == "next_step") {
        obj.action_value = null;
      } else if (this.currentStep.action_type == "to_url") {
        obj.action_value = this.stepsActionToURL;
      }

      for (const property in obj) {
        data.append(property, obj[property]);
      }
      data.append("advertisement_config_id", this.currentAd.id);

      this.loaderbtn = true;
      createAdvertisementStepConfig(data)
        .then((res) => {
          this.alertSuccessStep = true;
          setTimeout(() => {
            this.loaderbtn = false;
            this.alertSuccessStep = false;
            const data = { advertisement_id: this.currentPublicidad.id };
            getAdvertisementDetail(data).then((res) => {
              this.currentAd = res.advertisement.filter(
                (ad) => ad.id == this.currentAd.id
              )[0];
              this.createStepModal = false;
            });
          }, 1000);

          this.currentStep = {
            title: "",
            description: "",
            type: "",
            action_type: "",
            action_value: "",
            media: "",
          };
          this.previewcreatecurrentStep = "";
        })
        .catch((e) => {
          this.loaderbtn = false;
          this.alertErrorStep = true;
          setTimeout(() => {
            this.alertErrorStep = false;
          }, 1000);
        });
    },

    onAddParam() {
      this.stepsActionToAppRoute.params.push({ key: "", value: "" });
    },
    deleteParam(id) {
      this.stepsActionToAppRoute.params.splice(id, 1);
    },
    setStep(step, editOrDelete) {
      this.stepsActionToAppRoute = { ...this.stepsActionToAppRouteModel };
      this.stepsActionToURL = "";

      this.currentStep = { ...step };
      this.originalMediaStep = step.media;
      this.previewcreatecurrentStep = step.media.media_link;
      if (editOrDelete == "delete") {
        this.deleteStepModal = true;
      } else if (editOrDelete == "edit") {
        const params = [];

        if (step.action_type == "to_app_route") {
          const stepJson = JSON.parse(this.currentStep.action_value);
          const actionValueFormat = stepJson.params.map((param) => {
            for (const key in param) {
              params.push({ key, value: param[key] });
            }
          });
          this.stepsActionToAppRoute = stepJson;
          this.stepsActionToAppRoute.params = params;
          this.addParamsBtn = params?.length ? false : true;
        } else if (step.action_type == "to_url") {
          this.stepsActionToURL = step.action_value;
          this.addParamsbtn = false;
        }

        this.createStepModal = true;
        this.createOrUpdateStep = "update";
      }
    },
    updateStep() {
      const data = new FormData();
      const obj = { ...this.currentStep };

      if (this.currentStep.action_type == "to_app_route") {
        const paramsformat = this.stepsActionToAppRoute.params.map((param) => {
          return { [param.key]: param.value };
        });
        obj.action_value = { ...this.stepsActionToAppRoute };
        obj.action_value.params = paramsformat;
        obj.action_value = JSON.stringify({ ...obj.action_value });
      } else if (this.currentStep.action_type == "next_step") {
        obj.action_value = null;
      } else if (this.currentStep.action_type == "to_url") {
        obj.action_value = this.stepsActionToURL;
      }

      if (obj.media == this.originalMediaStep) {
        delete obj.media;
      }

      for (const property in obj) {
        data.append(property, obj[property]);
      }

      data.append("advertisement_step_config_id", this.currentStep.id);
      data.append("_method", "PATCH");

      this.loaderbtn = true;
      updateAdvertisementStepConfig(data)
        .then((res) => {
          this.alertSuccessStep = true;
          setTimeout(() => {
            this.loaderbtn = false;
            this.alertSuccessStep = false;
            const data = { advertisement_id: this.currentPublicidad.id };
            getAdvertisementDetail(data).then((res) => {
              this.currentAd = res.advertisement.filter(
                (ad) => ad.id == this.currentAd.id
              )[0];
              this.createStepModal = false;
            });
          }, 1000);
        })
        .catch((e) => {
          this.loaderbtn = false;
          this.alertErrorStep = true;
          setTimeout(() => {
            this.alertErrorStep = false;
          }, 1000);
        });
    },
    deleteStep() {
      const data = { advertisement_step_config_id: this.currentStep?.id };
      deleteAdvertisementStepConfig(data).then((res) => {
        const data = { advertisement_id: this.currentPublicidad.id };
        getAdvertisementDetail(data).then((res) => {
          this.currentAd = res.advertisement.filter(
            (ad) => ad.id == this.currentAd.id
          )[0];
          this.deleteStepModal = false;
        });
      });
    },
    createPublicidad() {
      const data = new FormData();
      const obj = { ...this.newAd };
      obj.start_date = moment(obj.start_date).format("YYYY-MM-DD h:mm:ss");
      obj.end_date = moment(obj.end_date).format("YYYY-MM-DD h:mm:ss");
      for (const property in obj) {
        data.append(property, obj[property]);
      }
      this.loaderbtn = true;
      createAdvertisement(data)
        .then((res) => {
          this.alertSuccess = true;
          setTimeout(() => {
            this.alertSuccess = false;
            this.loaderbtn = false;
          }, 1000);
          setTimeout(() => {
            this.createModal = false;
            this.newAd = {
              name: "",
              advertisement_type_id: "",
              action_value: "",
              action_type: "",
              media: "",
              start_date: "",
              end_date: "",
              forced_time: "",
              max_time: "",
            };
            this.fileNameNewAd = "";
            this.getPublicidades();
          }, 1500);
        })
        .catch((e) => {
          this.loaderbtn = false;
          this.alertError = true;
          setTimeout(() => {
            this.alertError = false;
          }, 1000);
        });
    },
    onChangeMediaNewAd(e) {
      this.newAd.media = e.target.files["0"];
      this.previewImgBG = URL.createObjectURL(e.target.files["0"]);
      this.fileNameNewAd = e.target.files["0"].name;
      this.fileTypeNewAd = e.target.files["0"].type;

      this.imgDimensions(e.target.files["0"]).then((res) => {
        this.mediaNewAd = res;
        const ratio = res.width / res.height;
        const ratiobool = ratio == 9 / 16;
        this.mediaNewAd.ratio = ratiobool;
      });
    },
    onChangeMediaUpdateAd(e) {
      this.currentAd.media = e.target.files["0"];
      this.previewUpdateMediaAd = URL.createObjectURL(e.target.files["0"]);
      this.fileNameUpdateAd = e.target.files["0"].name;
      this.fileTypeUpdateAd = e.target.files["0"].type;

      this.imgDimensions(e.target.files["0"]).then((res) => {
        this.mediaCurrentAd = res;
        const ratio = res.width / res.height;
        const ratiobool = ratio == 9 / 16;
        this.mediaCurrentAd.ratio = ratiobool;
      });
    },
    onChangeMediacurrentStep(e) {
      this.currentStep.media = e.target.files["0"];
      this.previewcreatecurrentStep = URL.createObjectURL(e.target.files["0"]);
      this.fileNamecurrentStep = e.target.files["0"].name;
      this.fileTypeStep = e.target.files["0"].type;
    },
    async imgDimensions(file) {
      const photoUrl = this.currentAd?.media?.media_link
        ? this.currentAd?.media?.media_link
        : URL.createObjectURL(file);
      const image = new Image();
      const imageDimensions = await new Promise((resolve) => {
        image.onload = () => {
          const dimensions = {
            width: image.width,
            height: image.height,
          };
          resolve(dimensions);
        };
        image.src = photoUrl;
      });
      return imageDimensions;
    },
    openStepModal() {
      this.createStepModal = !this.createStepModal;
      this.currentStep = { ...this.currentStepModel };
      this.stepsActionToAppRoute = { ...this.stepsActionToAppRouteModel };
      this.createOrUpdateStep = "create";
    },
    /*     sendNotification(advertisement_id) {
      const data = {advertisement_id}
      sendAdvertisementNotification(data).then(res => {
        this.alertSuccessNotification = true
        setTimeout(() => {
          this.alertSuccessNotification = false
        }, 3000);
      }).catch(e => {
          this.alertSuccessNotification = true
        setTimeout(() => {
          this.alertSuccessNotification = false
        }, 3000);
      })
    }, */
    openActionsModal(id) {
      this.idAddForActions = id;
      const data = {
        limit: this.perPage,
        page: this.page,
        advertisement_config_id: id,
      };
      getAdvertisementActions(data).then((res) => {
        this.actions = res.advertisement_actions;

        this.pagination = {
          page: this.pagination.page,
          perPage: this.pagination.perPage,
          total: res.advertisement_actions.length,
        };
        this.actionsModal = true;
      });
    },
    closeNotificationModal() {
      this.modalChooseNotificationUsers = false;
    },
    getSponsors() {
      getAdminSponsors().then((res) => {
        this.sponsors = res.sponsors.map((sponsor) => {
          return { value: sponsor.id, label: sponsor.name };
        });
          this.sponsor_id = this.sponsors[0]?.value;
          this.PublicidadesById()
      });
    },
    PublicidadesById() {
      this.getPublicidades();
    },
  },
  filters: {
    filterDate: function (date) {
      return moment(date).format("DD-MM-YYYY HH:mm");
    },
  },
  mounted() {
    this.getTypes();
    this.getSponsors();
    this.getPublicidades();
  },
  watch: {
    sponsor_id(value) {
      this.newAd.sponsor_id = value;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/style.scss";
.saveBtn {
  background: transparent
    linear-gradient(180deg, #D0282E 0%, #e66065 100%) 0% 0% no-repeat
    padding-box;
  border-radius: 7px;
  opacity: 1;
  top: 410px;
  left: 339px;
  width: 123px;
  height: 37px;
  font: normal normal normal 14px/18px Montserrat;
  color: #fff;
  border: none;
}
.file-input-group {
  input[type="file"] {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
}
.img-preview {
  width: 150px;
  height: 150px;
  object-fit: cover;
}
</style>
